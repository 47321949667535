import axios from "axios";

import { objectKeysToCamelCase } from "../../_common/utils/string";

export function getFiltro({ filtroId, leadId, etapaId, reaplicar, avaliacaoId, ciclo, somenteLeitura = false }) {
  let url;
  if (reaplicar) url = "/api/pipeline/Questionario/Reaplicar?leadid=1370055&historicoLeadAvaliacaoId=11012";
  else
    url = `/api/pipeline/Questionario/Buscar?id=${filtroId}&leadId=${leadId}&etapaId=${etapaId}&avaliacaoId=${avaliacaoId}&ciclo=${ciclo}&somenteLeitura=${somenteLeitura}`;
  return axios
    .post(url)
    .then(({ data }) => ({ ...data, perguntas: data.perguntas.sort(({ ordem: a }, { ordem: b }) => a - b) }));
}

export function avaliar(args) {
  return axios
    .post(`/api/pipeline/Questionario/finalizar`, {
      ...args,
    })
    .then(({ data }) => objectKeysToCamelCase(data));
}

export function reavaliar(args) {
  return axios.post(`/api/pipeline/Questionario/reavaliar`, args).then(({ data }) => data);
}

export function pausar(args) {
  return axios.post(`/api/pipeline/Questionario/pausar`, args).then(({ data }) => objectKeysToCamelCase(data));
}

export async function getFiltroPublico({ filtroId, leadId, avaliacaoId, ciclo }) {
  const url = `/api/pipeline/QuestionarioPublico/BuscarDetalhe?id=${filtroId}&leadIdPublico=${leadId}&avaliacaoId=${avaliacaoId}&ciclo=${ciclo}`;
  return axios
    .get(url)
    .then(({ data }) => ({ ...data, perguntas: data.perguntas.sort(({ ordem: a }, { ordem: b }) => a - b) }));
}
