import React from "react";
import PropTypes from "prop-types";
import { Typography, withStyles } from "@material-ui/core";

import { Flex } from "../../../_common";
import { StarRatingField } from "../../../_common/components/StarRating";

ResultadoScoreSimilaridade.propTypes = {
  score: PropTypes.number,
  msg: PropTypes.string,
};

function ResultadoScoreSimilaridade({ score, msg, classes, ...props }) {
  return (
    <Flex flexDirection="column" {...props}>
      <StarRatingField
        className={classes.starRatingField}
        label="Score de Similaridade é"
        numberOfStars={5}
        rating={score}
        readOnly
      />
      <Typography>{msg}</Typography>
    </Flex>
  );
}

export default withStyles({
  starRatingField: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
  },
  icon: {
    fontSize: 48,
  },
})(ResultadoScoreSimilaridade);
