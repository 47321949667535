import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent, CardActions, Button, withStyles } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Tipografia, Flex, createSnackbar } from "../../_common";
import { useQueryParams } from "../../hooks";

function Questionario({ children, titulo, perguntas, avaliar, reavaliar, pausar, readOnly, classes, abordagem }) {
  const { avaliacaoId } = useQueryParams();
  if (!perguntas.length) {
    createSnackbar("Não há perguntas nesse filtro");
    return <Redirect to="/spotter/base-leads/funil" />;
  }

  function save(id) {
    return id ? reavaliar(id) : avaliar();
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Tipografia tipo="tituloCard">{titulo}</Tipografia>
            {!readOnly && (
              <React.Fragment>
                <Tipografia>
                  <FormattedMessage defaultMessage="Preparado para aplicar o {titulo}?" values={{ titulo }} />
                </Tipografia>
                <Tipografia>
                  <FormattedMessage defaultMessage="Lembre-se: caso necessário, você pode continuar mais tarde." />
                </Tipografia>
              </React.Fragment>
            )}
          </Flex>
        }
      />
      {abordagem && (
        <CardContent className={classes.outline}>
          <Flex alignItems="center" justifyContent="center">
            <Tipografia className={classes.abordagemTitulo} tipo="tituloCard">
              <FormattedMessage defaultMessage="Abordagem" />
            </Tipografia>
          </Flex>
          <Tipografia className={classes.abordagemTexto}>{abordagem}</Tipografia>
        </CardContent>
      )}
      <CardContent className={classes.perguntas}>{perguntas.map(children)}</CardContent>
      {!readOnly && (
        <CardActions>
          <Flex justifyContent="flex-end" style={{ width: "100%" }}>
            <Button size="small" color="primary" onClick={pausar}>
              <FormattedMessage defaultMessage="Continuar depois" />
            </Button>
            <Button variant="contained" size="small" color="primary" onClick={() => save(avaliacaoId)}>
              <FormattedMessage defaultMessage="Avaliar" />
            </Button>
          </Flex>
        </CardActions>
      )}
    </Card>
  );
}

Questionario.propTypes = {
  children: PropTypes.func,
  titulo: PropTypes.string,
  perguntas: PropTypes.array,
  avaliar: PropTypes.func,
  reavaliar: PropTypes.func,
  pausar: PropTypes.func,
  readOnly: PropTypes.bool,
  classes: PropTypes.object,
  abordagem: PropTypes.string,
};

export default withStyles({
  root: {
    margin: 0,
  },
  outline: {
    padding: "25px 25px 45px 25px",
    margin: "24px 16px 24px 16px",
    border: "1px solid lightgrey",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
  },
  abordagemTitulo: { margin: "0 10px 20px 10px" },
  abordagemTexto: {
    lineHeight: "20px",
    margin: "0 10px 0 10px",
    whiteSpace: "pre-wrap",
  },
  perguntas: {
    whiteSpace: "pre-line",
  },
})(Questionario);
