import { createSelector } from "reselect";
import { set } from "dot-prop-immutable";

import actions from "./actions";
import { createReducer } from "../../_common/utils/redux";

const initialState = {
  id: undefined,
  nome: undefined,
  leadId: undefined,
  resultado: undefined,
  abordagem: "",
  // resultado: {
  //   id: 12037,
  //   pontuacao: 1145,
  //   aprovado: true,
  //   qualificacao: { descricao: "Muito Quente", ordem: 5, id: 1 },
  //   etapa: {
  //     id: 35137,
  //     nome: "Filtro 1",
  //     tipoGate: 2,
  //     proximaEtapaId: 35136,
  //     filtro: { id: 7015, nome: "Filtro 2" },
  //   },
  // },
  etapa: { id: undefined, nome: undefined, proximaEtapaId: undefined },
  perguntas: [
    // {dica,id,obrigatoria,ordem,tipo,resposta,respostas:[{
    // 	id,ordem,selecionado,texto
    // }]}
  ],
  mapIdIndex: {}, // estrutura auxiliar para evitar percorrer a lista ao buscar uma pergunta
  mapPaiIds: {}, // estrutura auxiliar para obter perguntas com dada respostaPai
};

const perguntasSelector = ({ filtro }) => filtro.perguntas;
export const getPerguntasRaiz = createSelector(perguntasSelector, perguntas =>
  perguntas.filter(pergunta => !pergunta.respostaPaiId).map(({ id }) => id)
);
export const getPergunta = ({ filtro }, { id }) => {
  const { mapIdIndex } = filtro;
  return filtro.perguntas[mapIdIndex[id]];
};
export const getPerguntasFilhas = ({ filtro }) => idR => {
  const { mapPaiIds } = filtro;
  return (mapPaiIds[idR] || []).flat().filter(Boolean);
};

export default createReducer(initialState, {
  [actions.BUSCAR]: (state, { perguntas, abordagem, ...data }) => {
    const mapIdIndex = getMapIdIndex(perguntas);
    const mapPaiIds = getMapPaiIds(perguntas);

    return { ...state, ...data, perguntas, abordagem, mapIdIndex, mapPaiIds };
  },
  [actions.ATUALIZAR]: (state, { pergunta, value }) => set(state, `perguntas.${pergunta}`, value),
  [actions.FINALIZAR]: (state, { resultado }) => set(state, "resultado", resultado),
});

const getMapIdIndex = perguntas => perguntas.reduce((map, pergunta, index) => ({ ...map, [pergunta.id]: index }), {});
const getMapPaiIds = perguntas =>
  perguntas.reduce((map, pergunta) => {
    const prev = map[pergunta.respostaPaiId] ? map[pergunta.respostaPaiId] : [];
    return { ...map, [pergunta.respostaPaiId]: [...prev, pergunta.id] };
  }, {});
