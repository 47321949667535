import React, { Fragment } from "react";
import { withStyles, Button, Popper, ClickAwayListener } from "@material-ui/core";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ModalDica from "./ModalDica";

Dica.propTypes = {
  text: PropTypes.string,
};

function Dica({ text }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <React.Fragment>
        <Button size="small" style={{ marginLeft: 10 }} onClick={handleClick} color="primary">
          <FormattedMessage defaultMessage="Ver dica" />
        </Button>

        <Popper
          open={open}
          placement="bottom-start"
          modifiers={{
            preventOverflow: { enabled: false },
            flip: { enabled: false },
            hide: {
              enabled: false,
            },
          }}
          anchorEl={anchorEl}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <ModalDica onClose={handleClose} text={text} />
          </ClickAwayListener>
        </Popper>
      </React.Fragment>
    </Fragment>
  );
}

export default withStyles({})(Dica);
