export const getInitialState = () => ({
  currentTab: null,
  data: [],
  searchTerm: "",
  somenteAtivos: true,
  canAdd: false,
  agenda: 0,
  isLoading: true,
  isGroup: false,
  modalEdit: {
    isOpen: false,
    target: null,
    showMoreOptions: false,
  },
  accessGroup: { id: null, name: null },
  mostrarColunaIntegracao: false,
  colunaCrm: false,
});
