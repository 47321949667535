import { getFiltro, avaliar, reavaliar, pausar, getFiltroPublico } from "./api";
import { createAction } from "../../_common/utils/redux";
import { AjaxBlackout, createSnackbar, objectKeysToCamelCase } from "../../_common";
import { TIPO_PERGUNTA } from "../../_common/constantes";
import createSnackbarAPIException from "../../_common/utils/snackbar/createSnackbarAPIException";

const actions = {
  SALVAR: "FILTRO/SALVAR",
  BUSCAR: "FILTRO/BUSCAR",
  AVALIAR: "FILTRO/AVALIAR",
  ATUALIZAR: "FILTRO/ATUALIZAR",
  FINALIZAR: "FILTRO/FINALIZAR",
};

export function buscarFiltro(params) {
  return async dispatch => {
    const data = await getFiltro({ ...params, filtroId: params.id });
    dispatch(createAction(actions.BUSCAR, { ...data, leadId: params.leadId }));
  };
}

export function buscarFiltroPublico(params) {
  return async dispatch => {
    const data = await getFiltroPublico({ ...params, filtroId: params.id });
    dispatch(createAction(actions.BUSCAR, { ...data, leadId: params.leadId }));
  };
}

export function getPerguntaId(id) {
  return (dispatch, getState) => {
    const { perguntas } = getState().filtro;
    return perguntas.find(per => per.id === id);
  };
}
export function getRespostasInitilValue() {
  return (dispatch, getState) => {
    const { perguntas } = getState().filtro;

    const [respostasAbertas, respostasFechadas] = perguntas.reduce(agruparRespostasIniciais, [[], []]);
    const respostas = {};
    if (respostasAbertas.length > 0) {
      respostasAbertas.forEach(resposta => {
        const [id] = Object.keys(resposta);
        const [value] = Object.values(resposta);
        respostas[id] = value;
      });
    }
    if (respostasFechadas.length > 0) {
      respostasFechadas.forEach(resposta => {
        const [id] = Object.keys(resposta);
        const [value] = Object.values(resposta);
        respostas[id] = value;
      });
    }
    return Object.keys(respostas).length ? respostas : undefined;
  };
}

function agruparRespostasIniciais([abertas, fechadas], { tipo, id: idP, respostas, resposta }) {
  if (tipo === TIPO_PERGUNTA.ABERTA) return [[...abertas, { [idP]: resposta || "" }], fechadas];
  const respostasSelecionadas = respostas.filter(({ selecionado }) => selecionado);
  return [
    abertas,
    [
      ...fechadas,
      ...(respostasSelecionadas.length ? respostasSelecionadas.map(resp => ({ [idP]: resp.id })) : [{ [idP]: "" }]),
    ],
  ];
}

export function pausarFiltro() {
  return async (dispatch, getState) => {
    AjaxBlackout.Show();
    try {
      const payload = gerarPayloadFiltro(getState().filtro);
      await pausar(payload);
      window.location = `/spotter/base-leads/funil`;
    } catch (err) {
      createSnackbar(err.response.data.status);
      AjaxBlackout.Hide();
    }
  };
}

export function avaliarLead(etapaId) {
  return async (dispatch, getState) => {
    AjaxBlackout.Show();
    try {
      const payload = gerarPayloadFiltro(getState().filtro, etapaId);
      const response = await avaliar(payload);
      const resultado = objectKeysToCamelCase(response);
      if (resultado?.mensagensIntegracao) resultado.mensagensIntegracao.forEach(message => createSnackbar(message));
      dispatch(createAction(actions.FINALIZAR, { resultado }));
    } catch (e) {
      setTimeout(() => {
        window.location.href = "/spotter/base-leads/funil";
      }, 500);
      createSnackbarAPIException(e);
    } finally {
      AjaxBlackout.Hide();
    }
  };
}

export function reavaliarLead(avaliacaoId, etapaId) {
  return async (dispatch, getState) => {
    AjaxBlackout.Show();
    try {
      const payload = gerarPayloadFiltro(getState().filtro, etapaId);
      const resultado = await reavaliar({ ...payload, avaliacaoAnteriorId: avaliacaoId });
      if (resultado?.mensagensIntegracao) resultado.mensagensIntegracao.forEach(message => createSnackbar(message));
      dispatch(createAction(actions.FINALIZAR, { resultado }));
    } catch (e) {
      setTimeout(() => {
        window.location.href = "/spotter/base-leads/funil";
      }, 500);
      createSnackbarAPIException(e);
    } finally {
      AjaxBlackout.Hide();
    }
  };
}

function gerarPayloadFiltro(filtro, etapaId) {
  const { id: filtroId, leadId, perguntas } = filtro;
  const [respostasAbertas, respostasFechadas] = perguntas.reduce(agruparRespostas, [[], []]);
  return {
    leadId,
    id: filtroId,
    etapaAtualId: etapaId,
    respostasAbertas,
    respostasFechadas,
  };
}

function agruparRespostas([abertas, fechadas], { tipo, id: idP, respostas, resposta }) {
  if (tipo === 3 && resposta) return [[...abertas, { perguntaId: idP, resposta }], fechadas];
  return [abertas, [...fechadas, ...respostas.filter(({ selecionado }) => selecionado).map(({ id: idR }) => idR)]];
}

export function atualizarResposta({ perguntaId, respostaId, value }) {
  return (dispatch, getState) => {
    const { mapIdIndex, perguntas } = getState().filtro;
    const pergunta = perguntas[mapIdIndex[perguntaId]];
    let nv;
    switch (pergunta.tipo) {
      case 1:
        nv = {
          ...pergunta,
          respostas: pergunta.respostas.map(
            ({ id, ...rest }) =>
              !respostaId || id === respostaId ? { ...rest, id, selecionado: value } : { ...rest, id }
          ),
        };
        break;
      case 2:
        nv = {
          ...pergunta,
          respostas: pergunta.respostas.map(
            ({ id, ...rest }) =>
              id === respostaId ? { ...rest, id, selecionado: true } : { ...rest, id, selecionado: false }
          ),
        };
        break;
      case 3:
        nv = { ...pergunta, resposta: value };
        break;
      default:
        nv = pergunta;
    }
    dispatch(createAction(actions.ATUALIZAR, { pergunta: mapIdIndex[perguntaId], value: nv }));
  };
}

export function limparRespostas({ perguntaId }) {
  return (dispatch, getState) => {
    const { mapIdIndex, perguntas } = getState().filtro;
    const { tipo } = perguntas[mapIdIndex[perguntaId]];
    dispatch(
      atualizarResposta({
        perguntaId,
        value: tipo === 3 ? "" : false,
      })
    );
  };
}

export function clearResultado() {
  return createAction(actions.FINALIZAR, {});
}

export default actions;
