import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Grid, Button, DialogContentText, withStyles, Paper } from "@material-ui/core";

ModalDica.propTypes = {
  onClose: PropTypes.func,
  text: PropTypes.string,
  classes: PropTypes.object,
};

function ModalDica({ onClose, text, classes }) {
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Grid
          item
          container
          xs
          alignItems="flex-start"
          justify="flex-start"
          style={{ overflow: "auto", height: "100%" }}
        >
          <DialogContentText className={classes.text}>{text}</DialogContentText>
          <Grid
            item
            container
            alignItems="flex-start"
            justify="flex-end"
            style={{
              marginBottom: 5,
              paddingRight: 5,
            }}
          >
            <Button style={{ color: "black", fontSize: "12px" }} onClick={() => onClose()}>
              <FormattedMessage defaultMessage="Fechar" />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default withStyles({
  root: {
    maxWidth: 600,
    padding: 10,
    paddingBottom: 0,
    paddingRight: 0,
    overflowY: "auto",
    maxHeight: 350,
  },
  text: {
    paddingRight: 16,
    color: "black",
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "12px",
    width: "max-content",
    whiteSpace: "break-spaces",
  },
})(ModalDica);
