import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  withStyles,
  LinearProgress,
} from "@material-ui/core";
import Warning from "@material-ui/icons/Warning";
import useSWR from "swr";
import { useHistory } from "react-router";
import { Tipografia, Flex } from "../../_common";
import ScoreSimilaridade from "./ScoreSimilaridade";
import { AgendarReuniao, IniciarFiltro, Vender } from "../lead/components";
import { TIPO_GATE, TIPO_PERFIL, PERMISSAO_ETAPA } from "../../_common/constantes";
import PassarManualmente from "../lead/components/PassarManualmente";
import { useQueryParams, useUsuario } from "../../hooks";

const TEMPERATURAS = ["Congelado", "Frio", "Morno", "Quente", "Muito quente"];

function Resultado({
  pontuacao,
  className,
  aprovado,
  qualificacao,
  classes,
  abordagem,
  leadId,
  // children,
  /** Props referentes ao Score de Similaridade */
  utilizaScore,
  qualificacaoEstrelas,
  mensagemRetorno,
}) {
  const intl = useIntl();
  const { data: painel, mutate, isValidating } = useSWR(`/api/pipeline/lead/painel?id=${leadId}`);
  const lead = React.useMemo(() => ({ ...painel, id: leadId }), [painel, leadId]);
  const { avaliacaoId } = useQueryParams();

  const { tipoPerfil: perfilUsuario, grupos } = useUsuario();
  const history = useHistory();
  const podeInteragir = React.useMemo(
    () => {
      if (avaliacaoId) return false;
      if (!leadId) return false;
      if (perfilUsuario === TIPO_PERFIL.GERENTE) return true;
      if (!painel) return false;
      return painel.etapa.permissoes.some(
        ({ tipoPerfil, permissao, grupoId }) =>
          (tipoPerfil === perfilUsuario || grupos.includes(grupoId)) && permissao === PERMISSAO_ETAPA.INTERAGIR
      );
    },
    [painel, leadId, avaliacaoId]
  );

  const loading = !painel || isValidating;

  return (
    <Card classes={{ root: className }}>
      <CardHeader
        title={
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Tipografia tipo="tituloCard">{intl.formatMessage({ defaultMessage: "Avaliação concluída" })}</Tipografia>
            <Tipografia>
              {intl.formatMessage({ defaultMessage: "Confira abaixo o resultado do seu filtro." })}
            </Tipografia>
          </Flex>
        }
      />

      <CardContent>
        <Flex flexDirection="column" className={classes.content}>
          <Flex justifyContent="space-between">
            <Flex flexDirection="column" className={classes.title}>
              <Tipografia cor="darkSecondaryText" tipo="notaLegendas">
                {intl.formatMessage({ defaultMessage: "Avaliação" })}
              </Tipografia>
              <Tipografia tipo="textoSidebar" className={classes.qualificacao}>
                {intl.formatMessage({ defaultMessage: "A qualificação do seu lead foi:" })}
              </Tipografia>
              <Tipografia tipo="navbar">
                {
                  [
                    intl.formatMessage({ defaultMessage: "Congelado" }),
                    intl.formatMessage({ defaultMessage: "Frio" }),
                    intl.formatMessage({ defaultMessage: "Morno" }),
                    intl.formatMessage({ defaultMessage: "Quente" }),
                    intl.formatMessage({ defaultMessage: "Muito quente" }),
                  ][qualificacao.ordem - 1]
                }{" "}
                ({pontuacao} {intl.formatMessage({ defaultMessage: "pontos" })})
              </Tipografia>
              <LinearProgress
                variant="determinate"
                value={10 + (qualificacao.ordem - 1) * 22.5}
                classes={{
                  root: classes.progress,
                  bar: classes.progressBar,
                  bar1Determinate: [
                    classes.congelado,
                    classes.frio,
                    classes.morno,
                    classes.quente,
                    classes.muitoQuente,
                  ][qualificacao.ordem - 1],
                }}
              />

              {utilizaScore && (
                <ScoreSimilaridade
                  className={classes.scoreSimilaridade}
                  score={qualificacaoEstrelas}
                  msg={mensagemRetorno}
                />
              )}
            </Flex>
          </Flex>

          {!aprovado && (
            <Flex alignItems="center" className={classes.pontuacaoInsuficiente}>
              <Warning className={classes.warningIcon} />
              <Tipografia tipo="textoSidebar">
                {intl.formatMessage({ defaultMessage: "Pontuação ou similaridade insuficientes:" })}
              </Tipografia>
              <Tipografia tipo="botoes">{intl.formatMessage({ defaultMessage: "Lead não qualificado" })}</Tipografia>
            </Flex>
          )}
        </Flex>

        {abordagem && (
          <CardContent className={classes.outline}>
            <Flex alignItems="center" justifyContent="center">
              <Tipografia className={classes.abordagemTitulo} tipo="tituloCard">
                {intl.formatMessage({ defaultMessage: "Abordagem" })}
              </Tipografia>
            </Flex>
            <Tipografia className={classes.abordagemTexto}>{abordagem}</Tipografia>
          </CardContent>
        )}
      </CardContent>

      {/* {children} */}

      {
        /* !children && */ <CardActions>
          {(!leadId || !loading) && (
            <Grid container spacing={16} justify="flex-end">
              <Grid item>
                <a href="/spotter/base-leads/funil">
                  <Button size="small" variant="contained" color={!aprovado || !podeInteragir ? "primary" : undefined}>
                    {intl.formatMessage({ defaultMessage: "Voltar à base de leads" })}
                  </Button>
                </a>
              </Grid>

              {podeInteragir && (
                <Grid item>
                  <IniciarFiltro lead={lead} type="text" variant="contained" color="primary" />
                  <AgendarReuniao lead={lead} redirecionarBaseLeads type="text" variant="contained" color="primary" />
                  {painel.etapa?.tipoGate === TIPO_GATE.VENDA && (
                    <Vender
                      lead={lead}
                      type="text"
                      variant="contained"
                      color="primary"
                      callback={success => {
                        if (success) {
                          mutate();
                          history.replace("/spotter/base-leads/funil");
                        }
                      }}
                    />
                  )}

                  {painel.etapa?.tipoGate === TIPO_GATE.MANUAL && (
                    <PassarManualmente
                      type="text"
                      variant="contained"
                      color="primary"
                      onTransfer={success => success && history.replace("/spotter/base-leads/funil")}
                      lead={lead}
                    />
                  )}
                </Grid>
              )}

              {/* {children} */}
            </Grid>
          )}
        </CardActions>
      }
    </Card>
  );
}

Resultado.propTypes = {
  className: PropTypes.string,
  pontuacao: PropTypes.number,
  aprovado: PropTypes.bool,
  qualificacao: PropTypes.shape({
    descricao: PropTypes.string,
    /** Define a temperatura da avaliação */
    ordem: PropTypes.oneOf(TEMPERATURAS.map((_, index) => index + 1)),
    id: PropTypes.number,
  }),
  leadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  abordagem: PropTypes.string,
  /** Determina se deve mostrar Score de Similaridade */
  utilizaScore: PropTypes.bool,
  /** Avaliação do Score de Similaridade */
  qualificacaoEstrelas: PropTypes.number,
  /** Mensagem do Score de Similaridade */
  mensagemRetorno: PropTypes.string,
};

export default withStyles({
  pontuacaoInsuficiente: { backgroundColor: "#fafafa", height: 56, padding: 16 },
  warningIcon: { margin: 8 },
  content: { border: "1px solid #e1e1e1", borderRadius: 10 },
  title: { flexGrow: 1, padding: 16 },
  qualificacao: { paddingBottom: 24, paddingTop: 4 },
  progress: {
    height: 8,
    borderRadius: 10,
    margin: "10px 0",
    backgroundColor: "#F9F9F9",
  },
  progressBar: {
    borderRadius: 10,
  },
  congelado: {},
  frio: { backgroundColor: "#41ADCF" },
  morno: { backgroundColor: "#FFA922" },
  quente: { backgroundColor: "#f36200" },
  muitoQuente: { backgroundColor: "#f30000" },
  abordagemTitulo: { margin: "0 10px 20px 10px" },
  abordagemTexto: {
    lineHeight: "20px",
    margin: "0 10px 0 10px",
    whiteSpace: "pre-wrap",
  },
  outline: {
    margin: "16px 0 36px",
    border: "1px solid lightgrey",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
  },
  scoreSimilaridade: { marginTop: 18 },
})(Resultado);
