import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { TIPO_PERGUNTA } from "../../_common/constantes";

import ModalDica from "./ModalDica";
import FastInput from "./FastInput";
import FastRadio from "./FastRadio";

import { Tipografia } from "../../_common";

function Respostas({ textoRespostaAberta, opcoes, tipoPergunta, formatacao, perguntaId, onChange, readOnly }) {
  if (tipoPergunta === TIPO_PERGUNTA.ESCOLHA_MULTIPLA)
    return opcoes.map(resposta => (
      <React.Fragment key={resposta.id}>
        <FormControlLabel
          label={
            <React.Fragment>
              <Tipografia>{resposta.texto}</Tipografia>
              {resposta.dica && <ModalDica text={resposta.dica} style={{ marginBottom: 10 }} />}
            </React.Fragment>
          }
          control={
            <Checkbox
              checked={resposta.selecionado}
              disabled={readOnly}
              color="primary"
              id={`checkbox-resposta-${resposta.id}`}
              onChange={() => onChange({ respostaId: resposta.id, value: !resposta.selecionado })}
            />
          }
        />
      </React.Fragment>
    ));

  if (tipoPergunta === TIPO_PERGUNTA.ESCOLHA_UNICA)
    return (
      <FastRadio
        selected={(opcoes.find(({ selecionado }) => selecionado) || { id: "" }).id.toString()}
        onChange={e => onChange({ respostaId: parseInt(e.target.value, 10), value: true })}
        opcoes={opcoes}
        disabled={readOnly}
      />
    );

  if (tipoPergunta === TIPO_PERGUNTA.ABERTA)
    return (
      <FastInput
        format={formatacao}
        disabled={readOnly}
        value={textoRespostaAberta}
        onChange={value => onChange({ perguntaId, value })}
      />
    );
}

Respostas.propTypes = {
  opcoes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      texto: PropTypes.string.isRequired,
      selecionado: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    })
  ),
  textoRespostaAberta: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  tipoPergunta: PropTypes.number,
  perguntaId: PropTypes.number,
};

export default Respostas;
