import React, { useCallback, useEffect, useState, useMemo } from "react";
import { defineMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Tabs, Tab, Divider, Paper, withStyles, Card, CardContent } from "@material-ui/core";
import useSWR from "swr";
import { Formik, Form, Field } from "formik";

import {
  buscarFiltro,
  avaliarLead,
  reavaliarLead,
  pausarFiltro,
  clearResultado,
  getPerguntaId,
  getRespostasInitilValue,
} from "./actions";
import { getPerguntasRaiz } from "./reducer";
import { Subheader, Flex, Loading, createSnackbar } from "../../_common";
import PerguntaContainer from "./Pergunta";
import Questionario from "./Questionario";
import Resultado from "./Resultado";
import { Empresa, Contatos, Dores, Produtos, Observacoes, CamposPersonalizados } from "../lead/components";
import useQueryParams from "../../hooks/useQueryParams";
import Actionbar from "./Actionbar";
import ModalAtividadeNewCont from "../atividade/components/ModalAtividadeNewCont";
import { useTipoPlanoSpotter, useUsuario } from "../../hooks";
import { TIPO_FORMATACAO_PERGUNTA, TIPO_PERGUNTA } from "../../_common/constantes";
import ModalNovoEmail from "../central-emails/ModalNovoEmail/ModalNovoEmail";
import MentorIAOutline from "../lead/components/MentorIA/MentorIAOutline";
import { getErrorMessage } from "../../_common/utils/string";

function Filtro({
  perguntas,
  abordagem,
  buscar,
  buscarPergunta,
  buscarResposta,
  avaliar,
  exigirContatoPrincipal,
  exigirDor,
  exigirProduto,
  reavaliar,
  filtro,
  pausar,
  resultado,
  clear,
  lead,
  classes,
}) {
  const { id, leadId, etapaId, reaplicar, readOnly: somenteLeitura, avaliacaoId, ciclo } = useQueryParams();
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [leads, setLeads] = useState([]);
  const initialForm = buscarResposta();
  const { data: painel, mutate, isValidating } = useSWR(
    () => (leadId ? `/api/pipeline/lead/painel?id=${leadId}` : null)
  );
  const { data: contatos } = useSWR(() => (exigirContatoPrincipal ? `/api/pipeline/lead/contatos?id=${leadId}` : null));
  const { data: dores } = useSWR(() => (exigirDor ? `/api/pipeline/lead/dores?id=${leadId}` : null));
  const { data: produtos } = useSWR(() => (exigirProduto ? `/api/pipeline/lead/produtos?id=${leadId}` : null));
  const { data: listaVisualizarCards } = useSWR("/api/pipeline/ConfigsAvancadas/DetalheLead/Buscar");
  const { isGerente, id: usuarioId } = useUsuario();
  const [isOpenCriarAtividade, setIsOpenCriarAtividade] = React.useState(false);
  const criarAtividade = React.useCallback(() => setIsOpenCriarAtividade(true));
  const isSpotterFull = useTipoPlanoSpotter();

  const [isEditing, setIsEditing] = useState({
    empresa: false,
    contatos: false,
    camposPersonalizados: false,
    observacoes: false,
    dores: false,
  });

  function handleChangeCallBack(bag, perguntaId, respostaId, value, tipo) {
    bag.setFieldTouched(perguntaId, true);

    if (tipo === TIPO_PERGUNTA.ESCOLHA_UNICA) {
      bag.setFieldValue(perguntaId, respostaId ?? "");
    }

    if (tipo === TIPO_PERGUNTA.ABERTA) {
      bag.setFieldValue(perguntaId, value ?? "");
    }

    if (tipo === TIPO_PERGUNTA.ESCOLHA_MULTIPLA) {
      let arrayValores = [];
      const valoresForm = bag.values[perguntaId];

      if (!respostaId && !value) {
        arrayValores = [];
      } else if (!valoresForm) {
        arrayValores.push(respostaId);
      } else {
        arrayValores = valoresForm;
        if (arrayValores.includes(respostaId)) {
          arrayValores = arrayValores.filter(e => e !== respostaId);
        } else {
          arrayValores.push(respostaId);
        }
      }

      bag.setFieldValue(perguntaId, arrayValores.length > 0 ? arrayValores : "");
    }
  }

  useEffect(
    () => {
      if (painel !== undefined) setLeads([painel]);
    },
    [isValidating]
  );

  useEffect(
    () => {
      if (id && leadId && etapaId) {
        setLoading(true);
        buscar({ id, leadId, etapaId, reaplicar, avaliacaoId, ciclo, somenteLeitura })
          .then(() => setLoading(false))
          .catch(e => {
            setTimeout(() => {
              window.location.href = "/spotter/base-leads/funil";
            }, 500);
            createSnackbar(getErrorMessage(e));
          });
      }
    },
    [id, leadId, etapaId]
  );

  const validateObrigatoriedade = () => {
    const errors = {};
    const contatoPrincipal = contatos?.find(c => c.principal);

    if (Object.values(isEditing).some(x => x === true)) {
      const erroEdicao = defineMessage({
        defaultMessage: "Necessário salvar campos em edição para prosseguir",
      });

      errors.edicao = intl.formatMessage(erroEdicao);
      createSnackbar(intl.formatMessage(erroEdicao));
    }

    if (exigirContatoPrincipal && (!contatoPrincipal?.emails?.length || !contatoPrincipal?.nome)) {
      const erroExigirContato = defineMessage({
        defaultMessage: "Necessário informar nome e e-mail do contato principal para prosseguir",
      });

      errors.contatos = intl.formatMessage(erroExigirContato);
      createSnackbar(intl.formatMessage(erroExigirContato));
    }

    if (exigirDor && !dores?.length) {
      const erroExigirDor = defineMessage({
        defaultMessage: "Necessário informar dor/objeção para prosseguir",
      });

      errors.dores = intl.formatMessage(erroExigirDor);
      createSnackbar(intl.formatMessage(erroExigirDor));
    }

    if (exigirProduto && !produtos?.length) {
      const erroExigirProduto = defineMessage({
        defaultMessage: "Necessário informar produto para prosseguir",
      });

      errors.produtos = intl.formatMessage(erroExigirProduto);
      createSnackbar(intl.formatMessage(erroExigirProduto));
    }

    return errors;
  };

  const validate = useCallback((valor, pergunta) => {
    const { obrigatoria, formatacao } = buscarPergunta(pergunta);
    if (obrigatoria && !valor) return intl.formatMessage({ defaultMessage: "* Campo obrigatório" });
    if (formatacao === TIPO_FORMATACAO_PERGUNTA.CPF_CNPJ) {
      const cpfCnpjSemMask = valor.replace(/\D/g, "").trim();
      const isCpfCnpj = cpfCnpjSemMask.length === 11 || cpfCnpjSemMask.length === 14;
      if (cpfCnpjSemMask.length && !isCpfCnpj) {
        return intl.formatMessage({ defaultMessage: "O número deve ser um CPF ou CNPJ válido" });
      }
    }
    return undefined;
  }, []);

  useEffect(() => clear, [id, etapaId]);

  async function onSave() {
    mutate(async () => {
      if (avaliacaoId) await reavaliar(avaliacaoId, etapaId);
      else await avaliar(etapaId);
      document.dispatchEvent(new Event("lead.dores.reload"));
      document.dispatchEvent(new Event("lead.produtos.reload"));
      document.dispatchEvent(new Event("lead.produtosVendidos.reload"));
    }, true);
  }

  return (
    <Flex flexDirection="column">
      {isOpenCriarAtividade && (
        <ModalAtividadeNewCont
          open={isOpenCriarAtividade}
          onClose={() => setIsOpenCriarAtividade(false)}
          isGerente={isGerente}
          lead={{ id: leadId, nome: painel?.nome }}
          preVendedorId={!isGerente ? usuarioId : null}
        />
      )}
      <Subheader
        square
        titulo={
          filtro ||
          intl.formatMessage({
            defaultMessage: "Filtro...",
          })
        }
        breadcrumb={[
          {
            titulo:
              filtro ||
              intl.formatMessage({
                defaultMessage: "Filtro...",
              }),
          },
        ]}
        classes={{ root: classes.header }}
      />

      <Paper square className={classes.fixedHeader}>
        <Flex className={classes.flexQuestionario} justifyContent="space-between" alignItems="center">
          <Tabs value="Questionário" className={classes.tab}>
            <Tab value="Questionário" label={intl.formatMessage({ defaultMessage: "Questionário" })} />
          </Tabs>
          <Actionbar
            leadId={leadId}
            etapaId={etapaId}
            pausar={!somenteLeitura ? pausar : null}
            criarAtividade={criarAtividade}
            leads={leads}
          />
        </Flex>
      </Paper>
      <Flex className={classes.container}>
        {loading && (
          <Card className={classes.leftPane}>
            <Loading isLoading />
          </Card>
        )}

        {!loading &&
          !resultado && (
            <Formik
              onSubmit={onSave}
              validateOnMount={false}
              validateOnChange={false}
              validateOnBlur={false}
              validate={validateObrigatoriedade}
              initialValues={initialForm}
            >
              {bag => (
                <Form className={classes.leftPane}>
                  <Questionario
                    readOnly={somenteLeitura}
                    classes={{ root: classes.questionario }}
                    titulo={filtro}
                    perguntas={perguntas}
                    avaliar={bag.handleSubmit}
                    reavaliar={bag.handleSubmit}
                    pausar={pausar}
                    abordagem={abordagem}
                    {...bag}
                  >
                    {pergunta => (
                      <Flex className={classes.perguntaRaiz} key={pergunta}>
                        <Field
                          handleChangeCallBack={({ perguntaId, respostaId, value, tipo, obrigatoria: ob }) =>
                            handleChangeCallBack(bag, perguntaId, respostaId, value, tipo, ob)
                          }
                          name={pergunta}
                          id={pergunta}
                          component={PerguntaContainer}
                          readOnly={somenteLeitura}
                          validate={value => validate(value, pergunta)}
                        />
                        <Divider />
                      </Flex>
                    )}
                  </Questionario>
                </Form>
              )}
            </Formik>
          )}

        {!loading && !!resultado && <Resultado className={classes.leftPane} leadId={leadId} {...resultado} />}

        <Card className={classes.rightPane}>
          <CardContent classes={{ root: classes.cardContent }}>
            {useMemo(
              () => (
                <Flex flexDirection="column">
                  {leadId && (
                    <Empresa lead={painel} id={leadId} isEditing={e => setIsEditing(p => ({ ...p, empresa: e }))} />
                  )}
                  {leadId &&
                    listaVisualizarCards?.visualizarCardMentorIa && (
                      <MentorIAOutline filtro={false} card id={leadId} lead={lead} />
                    )}
                  {leadId && (
                    <Contatos lead={painel} id={leadId} isEditing={e => setIsEditing(p => ({ ...p, contatos: e }))} />
                  )}
                  {leadId && (
                    <CamposPersonalizados
                      lead={painel}
                      entityId={leadId}
                      isEditing={e => setIsEditing(p => ({ ...p, camposPersonalizados: e }))}
                    />
                  )}
                  {leadId && (
                    <Observacoes
                      lead={painel}
                      id={leadId}
                      isEditing={e => setIsEditing(p => ({ ...p, observacoes: e }))}
                    />
                  )}
                  {leadId &&
                    isSpotterFull && (
                      <Dores lead={painel} id={leadId} isEditing={e => setIsEditing(p => ({ ...p, dores: e }))} />
                    )}
                  {leadId && isSpotterFull && <Produtos lead={painel} id={leadId} />}
                </Flex>
              ),
              [leadId, painel, lead]
            )}
          </CardContent>
        </Card>
      </Flex>
      <ModalNovoEmail />
    </Flex>
  );
}

Filtro.propTypes = {
  lead: PropTypes.object.isRequired,
  perguntas: PropTypes.array,
  abordagem: PropTypes.string,
  buscarPergunta: PropTypes.func,
  buscarResposta: PropTypes.func,
  buscar: PropTypes.func,
  avaliar: PropTypes.func,
  reavaliar: PropTypes.func,
  pausar: PropTypes.func,
  exigirContatoPrincipal: PropTypes.bool,
  exigirDor: PropTypes.bool,
  exigirProduto: PropTypes.bool,
  classes: PropTypes.object,
  resultado: PropTypes.object,
  clear: PropTypes.func,
  filtro: PropTypes.string,
  mapPai: PropTypes.object,
};

const enhance = compose(
  connect(
    ({ filtro }) => ({
      perguntas: getPerguntasRaiz({ filtro }),
      exigirContatoPrincipal: filtro.exigirContatoPrincipal,
      exigirDor: filtro.exigirDor,
      exigirProduto: filtro.exigirProduto,
      filtro: filtro.nome,
      resultado: filtro.resultado,
      mapPai: filtro.mapPaiIds,
      abordagem: filtro.abordagem,
    }),
    {
      buscar: buscarFiltro,
      avaliar: avaliarLead,
      reavaliar: reavaliarLead,
      pausar: pausarFiltro,
      clear: clearResultado,
      buscarPergunta: getPerguntaId,
      buscarResposta: getRespostasInitilValue,
    }
  ),
  withRouter,
  withStyles(theme => ({
    container: {
      margin: "0 24px",
    },
    header: {
      padding: "16px 29px 0 29px",
      marginBottom: 0,
      backgroundColor: "#F5F5F5",
    },
    fixedHeader: {
      position: "sticky",
      zIndex: 2,
      top: 0,
      boxShadow: "0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
      paddingRight: 34,
      marginBottom: 16,
    },
    flexQuestionario: { width: "100%", height: 56 },
    tab: { alignSelf: "flex-end" },
    perguntaRaiz: {
      padding: 25,
      border: "1px solid lightgrey",
      borderRadius: 10,
      marginBottom: 20,
    },
    lead: {
      flexGrow: 1,
    },
    leftPane: {
      width: "60%",
      margin: "0 16px 0 0",
    },
    rightPane: {
      width: "40%",
      height: "70vh",
      position: "sticky",
      top: 76,
      flexGrow: 1,
      margin: 0,
    },
    questionario: {
      width: "100%",
    },
    perguntaLabel: {
      width: "100%",
      fontSize: "25px",
    },
    cardContent: {
      height: "94%",
      overflowY: "auto",
      margin: 16,
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    contato: {
      margin: "0 -20px",
      padding: "5px 20px",
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
    icone: {
      height: 20,
      width: 20,
    },
  }))
);

export default enhance(Filtro);
