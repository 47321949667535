import React, { useMemo } from "react";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";

import ModalDica from "./ModalDica";

import { Tipografia } from "../../_common";

export default function FastRadio({ selected, onChange, opcoes, disabled }) {
  return useMemo(
    () => (
      <RadioGroup value={selected} onChange={onChange}>
        {opcoes.map(resposta => (
          <FormControlLabel
            key={resposta.id}
            value={resposta.id.toString()}
            control={<Radio color="primary" />}
            disabled={disabled}
            label={
              <React.Fragment>
                <Tipografia>{resposta.texto}</Tipografia>
                {resposta.dica && <ModalDica text={resposta.dica} style={{ marginBottom: 10 }} />}
              </React.Fragment>
            }
          />
        ))}
      </RadioGroup>
    ),
    [opcoes]
  );
}
