import { EQUIPE_TAB_PATH } from "./constants";

const USERS_API_BASE_URL = "/Api/Pipeline/Usuario1";
const createUsersApiEndPoint = path => `${USERS_API_BASE_URL}/${path}`;

export const usersAPI = {
  list: {
    [EQUIPE_TAB_PATH.prevendedor]: (somenteAtivos = true) =>
      createUsersApiEndPoint(`ListarUsuarios?tipo=prevendedor&somenteAtivos=${somenteAtivos}`),
    [EQUIPE_TAB_PATH.vendedor]: (somenteAtivos = true) =>
      createUsersApiEndPoint(`ListarUsuarios?tipo=vendedor&somenteAtivos=${somenteAtivos}`),
    [EQUIPE_TAB_PATH.gerente]: (somenteAtivos = true) =>
      createUsersApiEndPoint(`ListarUsuarios?tipo=gerente&somenteAtivos=${somenteAtivos}`),
  },
  findById: id => createUsersApiEndPoint(`Buscar?id=${id}&horarioComercial=true`),
  save: () => createUsersApiEndPoint(`Salvar`),
  remove: userId => createUsersApiEndPoint(`Remover?usuarioId=${userId}`),
  resetPassword: userId => createUsersApiEndPoint(`ResetarSenha?usuarioId=${userId}`),
  changeActiveStatus: ({ id, ativo }) =>
    createUsersApiEndPoint(`MudarStatusUsuario?usuarioId=${id}&statusUsuario=${ativo}`),
};

export const groupsAPI = {
  list: (somenteAtivos = true) => `/api/pipeline/Grupo/Listar?somenteAtivos=${somenteAtivos}`,
  findById: group => `/api/pipeline/Grupo/Buscar?id=${group.id}`,
  save: () => `/api/pipeline/Grupo/Salvar`,
  remove: group => `/api/pipeline/Grupo/Remover?id=${group.id}`,
  changeActiveStatus: ({ id, ativo }) => `/api/pipeline/Grupo/MudarStatusGrupo?id=${id}&statusGrupo=${ativo}`,
};
