import { getInitialState } from "./initialState";
import { types } from "./actions";
import { EQUIPE_TAB_PATH } from "../constants";

export default function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case types.SET_CURRENT_TAB: {
      const currentTab = action.payload;
      return {
        ...state,
        currentTab,
        isGroup: currentTab === EQUIPE_TAB_PATH.grupos,
        searchTerm: "",
      };
    }
    case types.REQUEST_DATA: {
      return {
        ...state,
        isLoading: true,
        data: [],
        canAdd: false,
      };
    }
    case types.RECEIVE_DATA_SUCCESS: {
      const { data, canAdd, agenda, accessGroup, mostrarColunaIntegracao, colunaCrm } = action.payload;
      return {
        ...state,
        isLoading: false,
        data,
        canAdd,
        agenda,
        accessGroup,
        mostrarColunaIntegracao,
        colunaCrm,
      };
    }
    case types.RECEIVE_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        data: [],
        canAdd: !!state.isGroup,
      };
    }
    case types.ON_CHANGE_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }
    case types.OPEN_MODAL_EDIT: {
      return {
        ...state,
        modalEdit: {
          ...state.modalEdit,
          isOpen: true,
          target: action.payload,
        },
      };
    }
    case types.CLOSE_MODAL_EDIT: {
      return {
        ...state,
        modalEdit: {
          ...state.modalEdit,
          isOpen: false,
          showMoreOptions: false,
        },
      };
    }
    case types.TOOGLE_SHOW_MORE_OPTIONS: {
      return {
        ...state,
        modalEdit: {
          ...state.modalEdit,
          showMoreOptions: action.payload,
        },
      };
    }
    case types.ON_CHECK_INATIVOS: {
      return {
        ...state,
        somenteAtivos: !action.payload,
      };
    }
    default:
      return state;
  }
}
