import React, { useMemo, useEffect, useRef } from "react";
import { FormControl, Input, TextField } from "@material-ui/core";

import { useDebouncedState } from "../../hooks";
import TextMaskCpfCnpj from "../../components/TextMaskCpfCnpj";
import CurrencyInput from "../../components/CurrencyInput";

import { TIPO_FORMATACAO_PERGUNTA } from "../../_common/constantes";

export default function FastInput({ disabled, value, onChange, format }) {
  const [debounced, setImmediate, immediate] = useDebouncedState(value, 600);
  const firstRender = useRef(true);

  useEffect(
    () => {
      setImmediate(value);
    },
    [value]
  );

  useEffect(
    () => {
      if (firstRender.current) firstRender.current = false;
      else onChange(debounced);
    },
    [debounced]
  );

  return useMemo(
    () => {
      if (format === TIPO_FORMATACAO_PERGUNTA.CPF_CNPJ)
        return (
          <FormControl fullWidth>
            <Input
              inputComponent={TextMaskCpfCnpj}
              multiline
              disabled={disabled}
              value={immediate}
              onChange={e => setImmediate(e.target.value)}
              fullWidth
            />
          </FormControl>
        );
      if (format === TIPO_FORMATACAO_PERGUNTA.MOEDA)
        return (
          <FormControl fullWidth>
            <Input
              inputComponent={CurrencyInput}
              multiline
              disabled={disabled}
              value={immediate}
              onChange={e => setImmediate(e.target.value)}
              inputProps={{ allowEmptyFormatting: false }}
              fullWidth
            />
          </FormControl>
        );
      if (format === TIPO_FORMATACAO_PERGUNTA.DATA)
        return (
          <FormControl fullWidth>
            <TextField
              type="date"
              disabled={disabled}
              value={immediate}
              onChange={e => setImmediate(e.target.value)}
              fullWidth
            />
          </FormControl>
        );
      return (
        <FormControl fullWidth>
          <Input
            multiline
            disabled={disabled}
            value={immediate}
            onChange={e => setImmediate(e.target.value)}
            fullWidth
          />
        </FormControl>
      );
    },
    [immediate, format]
  );
}
