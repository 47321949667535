import { defineMessage } from "react-intl";

const equipeAppUrl = path => `/spotter/equipe/${path}`;

export const GERENTE_ADMIN__TIPO_PERFIL_ID = 5;

export const EQUIPE_TAB_PATH = {
  prevendedor: "prevendedor",
  vendedor: "vendedor",
  gerente: "gerente",
  grupos: "grupos",
  usuario: "usuario",
};
const { prevendedor, vendedor, gerente, grupos, usuario } = EQUIPE_TAB_PATH;

export const EQUIPE_DEFS = {
  [prevendedor]: {
    tipoPerfilId: 2,
    tab: prevendedor,
    url: equipeAppUrl(prevendedor),
    description: {
      plural: defineMessage({ defaultMessage: "Pré-Vendedores" }),
      singular: defineMessage({ defaultMessage: "Pré-Vendedor" }),
    },
  },
  [vendedor]: {
    tipoPerfilId: 6,
    tab: vendedor,
    url: equipeAppUrl(vendedor),
    description: {
      plural: defineMessage({ defaultMessage: "Vendedores" }),
      singular: defineMessage({ defaultMessage: "Vendedor" }),
    },
  },
  [gerente]: {
    tipoPerfilId: 1,
    tab: gerente,
    url: equipeAppUrl(gerente),
    description: {
      plural: defineMessage({ defaultMessage: "Gerentes" }),
      singular: defineMessage({ defaultMessage: "Gerente" }),
    },
  },
  [grupos]: {
    url: equipeAppUrl(grupos),
    tab: grupos,
    description: {
      plural: defineMessage({ defaultMessage: "Grupos" }),
      singular: defineMessage({ defaultMessage: "Grupo" }),
    },
  },
};

export const EQUIPE_TABS = [EQUIPE_DEFS.prevendedor, EQUIPE_DEFS.vendedor, EQUIPE_DEFS.gerente, EQUIPE_DEFS.grupos];

export const getCurrentTabDef = tab => EQUIPE_DEFS[tab] || { description: { plural: "", singular: "" } };

export const ajustaEquipeDefsSpotterBasic = () => {
  const defs = { ...EQUIPE_DEFS };

  defs.prevendedor.tab = usuario;
  defs.prevendedor.description = {
    plural: defineMessage({
      defaultMessage: "Usuários",
    }),
    singular: defineMessage({ defaultMessage: "Usuário" }),
  };
};
