import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import Business from "@material-ui/icons/Business";
import PauseCircleFilledSharp from "@material-ui/icons/PauseCircleFilledSharp";
import Assignment from "@material-ui/icons/Assignment";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import { Flex } from "../../_common";
import { injectSheet } from "../../_common/hocs";
import { useUsuario, usePermission } from "../../hooks";
import { Descartar, Ligar, RegistrarLigacao } from "../lead/components";
import permissions from "../../_common/permissions";
import LigacaoContatosLead from "../detalhes-lead/components/LigacaoContatosLead/LigacaoContatosLead";

const Actionbar = ({ pausar, criarAtividade, leadId, etapaId, children, classes, leads }) => {
  const { telefoniaIntegrada } = useUsuario();
  const hasPermission = usePermission(permissions.MODULOATIVIDADES, leads);
  const [modalLigacaoIsOpen, setModalLigacaoIsOpen] = useState(false);
  const intl = useIntl();

  return (
    <>
      {modalLigacaoIsOpen && (
        <LigacaoContatosLead
          onClose={() => {
            setModalLigacaoIsOpen(false);
          }}
          leadId={leadId.toString()}
        />
      )}
      <Flex justifyContent="space-between" alignItems="center">
        {telefoniaIntegrada && leadId && <Ligar id={leadId} setModalLigacaoIsOpen={setModalLigacaoIsOpen} />}
        <RegistrarLigacao
          color="primary"
          type="text"
          id={leadId}
          etapaId={etapaId}
          callback={success => {
            if (success) window.location.href = "/spotter/base-leads/funil";
          }}
        />
        <Descartar
          ids={[leadId]}
          callback={success => {
            if (success) window.location.href = "/spotter/base-leads/funil";
          }}
          type="text"
          label={intl.formatMessage({ defaultMessage: "Descartar lead" })}
          leads={leads}
        />
        {pausar && (
          <Tooltip title={intl.formatMessage({ defaultMessage: "Continuar depois" })}>
            <IconButton onClick={pausar}>
              <PauseCircleFilledSharp className={classes.icone} />
            </IconButton>
          </Tooltip>
        )}
        {hasPermission && (
          <Tooltip title={intl.formatMessage({ defaultMessage: "Criar atividade" })}>
            <IconButton onClick={criarAtividade}>
              <AssignmentTurnedIn className={classes.icone} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={intl.formatMessage({ defaultMessage: "Ver atividades do lead" })}>
          <Link to={`/spotter/atividades?leadId=${leadId}`}>
            <IconButton>
              <Assignment className={classes.icone} />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ defaultMessage: "Ver detalhes do lead" })}>
          <Link to={`/spotter/detalhes-lead/${leadId}`}>
            <IconButton>
              <Business className={classes.icone} />
            </IconButton>
          </Link>
        </Tooltip>
        {children}
      </Flex>
    </>
  );
};

Actionbar.propTypes = {
  pausar: PropTypes.func,
  criarAtividade: PropTypes.func,
  leadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  etapaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  classes: PropTypes.object,
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      situacao: PropTypes.number,
      etapa: PropTypes.shape({ posVenda: PropTypes.bool }),
    })
  ),
};

export default injectSheet({
  icone: {
    height: 20,
    width: 20,
  },
})(Actionbar);
