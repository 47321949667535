import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field } from "formik";
import { get } from "dot-prop-immutable";
import { FormattedMessage } from "react-intl";
import { Button, Divider, FormLabel, FormControl, FormHelperText } from "@material-ui/core";
import ModalDica from "./ModalDica";
import { Flex } from "../../_common";
import { atualizarResposta, limparRespostas } from "./actions";
import { getPergunta } from "./reducer";
import Respostas from "./Respostas";
import { maybeCallback } from "../../_common/utils/fp";

export function Pergunta({
  pergunta,
  perguntas,
  ordem,
  mapPai,
  mapIdIndex,
  onChange,
  handleChangeCallBack,
  onClear,
  readOnly,
  ...bag
}) {
  function clear() {
    if (onClear) onClear({ perguntaId: pergunta.id });

    maybeCallback(handleChangeCallBack)({
      perguntaId: pergunta.id,
      respostaId: undefined,
      value: undefined,
      tipo: pergunta.tipo,
      obrigatoria: pergunta.obrigatoria,
    });
  }

  useEffect(() => clear, []);

  const error = get(bag?.errors ?? bag?.form?.errors, pergunta.id);
  const touched = get(bag?.touched ?? bag?.form?.touched, pergunta.id);

  return (
    <Flex style={{ width: "100%" }} flexDirection="column" alignItems="flex-start">
      <FormControl style={{ width: "100%" }} error={error && touched}>
        <Flex style={{ alignItems: "baseline", marginBottom: 20 }}>
          <FormLabel error={error && touched} style={{ fontSize: 18 }}>{`${ordem ? `${ordem}.` : ""}${
            pergunta.ordem
          } - ${pergunta.texto}${pergunta.obrigatoria ? " *" : ""}`}</FormLabel>
          {pergunta.dica && <ModalDica text={pergunta.dica} style={{ marginBottom: 10 }} />}
        </Flex>

        <Respostas
          readOnly={readOnly}
          opcoes={pergunta.respostas}
          tipoPergunta={pergunta.tipo}
          textoRespostaAberta={pergunta.resposta}
          formatacao={pergunta.formatacao}
          onChange={({ value, respostaId }) => {
            onChange({ perguntaId: pergunta.id, respostaId, value, tipo: pergunta.tipo });
            handleChangeCallBack({
              perguntaId: pergunta.id,
              respostaId,
              value,
              tipo: pergunta.tipo,
              obrigatoria: pergunta.obrigatoria,
            });
          }}
        />

        {onClear &&
          !readOnly && (
            <Flex justifyContent="flex-start">
              <Button color="primary" onClick={clear} style={{ width: "100px" }}>
                <FormattedMessage defaultMessage="Limpar" />
              </Button>
            </Flex>
          )}
        <br />

        {touched &&
          !!error && (
            <FormHelperText id="name-error-text" error={!!error && touched}>
              {error}
            </FormHelperText>
          )}
      </FormControl>
      {pergunta.respostas
        .filter(({ selecionado }) => selecionado)
        .map(({ id }) => mapPai[id])
        .flat()
        .filter(Boolean)
        .map(id => (
          <React.Fragment key={id}>
            <Divider />

            <Field
              handleChangeCallBack={handleChangeCallBack}
              name={id}
              id={id}
              readOnly={readOnly}
              component={PerguntaContainer}
              validate={perguntas[mapIdIndex[id]]?.obrigatoria && validate}
              ordem={ordem ? `${ordem}.${pergunta.ordem}` : pergunta.ordem}
            />
          </React.Fragment>
        ))}
    </Flex>
  );
}

function validate(valor) {
  return !valor ? "* Campo obrigatório" : undefined;
}

Pergunta.propTypes = {
  pergunta: PropTypes.shape({
    id: PropTypes.number,
    ordem: PropTypes.number,
    tipo: PropTypes.number,
    texto: PropTypes.string,
    obrigatoria: PropTypes.bool,
    dica: PropTypes.string,
    respostas: PropTypes.array,
    resposta: PropTypes.string,
    formatacao: PropTypes.number,
  }),
  perguntas: PropTypes.array,
  ordem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mapPai: PropTypes.object,
  mapIdIndex: PropTypes.object,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  readOnly: PropTypes.bool,
  handleChangeCallBack: PropTypes.func,
};

const PerguntaContainer = connect(
  ({ filtro }, { id, pergunta }) => ({
    pergunta: pergunta ?? getPergunta({ filtro }, { id }),
    perguntas: filtro.perguntas,
    mapPai: filtro.mapPaiIds,
    mapIdIndex: filtro.mapIdIndex,
  }),
  {
    onChange: atualizarResposta,
    onClear: limparRespostas,
  }
)(Pergunta);

export default PerguntaContainer;
