import axios from "axios";
import { mutate } from "swr";
import { createAction } from "../../../_common/utils/redux";
import createSnackBar from "../../../_common/utils/snackbar/createSnackbar";
import { usersAPI, groupsAPI } from "../apis";
import { AjaxBlackout, createSnackbar } from "../../../_common";
import { withAjaxBlackoutErrorMessage } from "../../../_common/utils/blackout/blackout";
import { EQUIPE_DEFS, EQUIPE_TAB_PATH } from "../constants";
import { appIntl } from "../../../_common/containers/ConnectedIntlProvider";

export const types = {
  SET_CURRENT_TAB: "EQUIPE/SET_CURRENT_TAB",
  OPEN_MODAL_EDIT: "EQUIPE/OPEN_MODAL_EDIT_USER",
  CLOSE_MODAL_EDIT: "EQUIPE/CLOSE_MODAL_EDIT_USER",
  ON_CHANGE_SEARCH_TERM: "EQUIPE/ON_CHANGE_SEARCH_TERM",
  REQUEST_DATA: "EQUIPE/REQUEST_DATA",
  RECEIVE_DATA_SUCCESS: "EQUIPE/RECEIVE_DATA_SUCCESS",
  RECEIVE_DATA_ERROR: "EQUIPE/RECEIVE_DATA_ERROR",
  TOOGLE_SHOW_MORE_OPTIONS: "EQUIPE/TOOGLE_SHOW_MORE_OPTIONS",
  ON_CHECK_INATIVOS: "EQUIPE/ON_CHECK_INATIVOS",
};

export const setCurrentTab = tab => createAction(types.SET_CURRENT_TAB, tab);
export const closeModalEdit = () => createAction(types.CLOSE_MODAL_EDIT);
export const openModalEdit = target => createAction(types.OPEN_MODAL_EDIT, target);
export const onChangeSearchTerm = value => createAction(types.ON_CHANGE_SEARCH_TERM, value);
export const requestData = () => createAction(types.REQUEST_DATA);
export const receiveDataSuccess = data => createAction(types.RECEIVE_DATA_SUCCESS, data);
export const receiveDataError = () => createAction(types.RECEIVE_DATA_ERROR);
export const toogleShowMoreOptions = value => createAction(types.TOOGLE_SHOW_MORE_OPTIONS, value);

export const getCurrentAccessGroup = state => state.equipe?.accessGroup?.name;

const loadUsers = () => async (dispatch, getState) => {
  const { currentTab, somenteAtivos } = getState().equipe;
  dispatch(requestData());
  const listUserType = usersAPI.list[currentTab];
  const endPoint = listUserType(somenteAtivos);
  const {
    data: { usuarios: data, agendaIntegrada: agenda, conjuntoAcesso, mostrarColunaIntegracao, colunaCrm },
  } = await axios.get(endPoint);
  if (currentTab === getState().equipe.currentTab)
    dispatch(
      receiveDataSuccess({
        data,
        agenda,
        accessGroup: {
          name: conjuntoAcesso.descricao,
          id: conjuntoAcesso.id,
        },
        mostrarColunaIntegracao,
        canAdd: true,
        colunaCrm,
      })
    );
};

export const toggleUserActive = targetUser => async dispatch => {
  withAjaxBlackoutErrorMessage(async () => {
    const intl = appIntl();
    const user = { ...targetUser, ativo: !targetUser.ativo };
    const url = usersAPI.changeActiveStatus(user);
    await axios.post(url);
    dispatch(loadUsers());
    if (user.acessaSpotter) mutate("/api/pipeline/empresa/limiteusuarios");
    const statusText = user.ativo
      ? intl.formatMessage({ defaultMessage: "ativado" })
      : intl.formatMessage({ defaultMessage: "desativado" });
    createSnackBar(intl.formatMessage({ defaultMessage: "Usuário {statusText} com sucesso." }, { statusText }));
  });
};

export const toggleGroupActive = targetGroup => async dispatch => {
  withAjaxBlackoutErrorMessage(async () => {
    const intl = appIntl();
    const group = { ...targetGroup, ativo: !targetGroup.ativo };
    const url = groupsAPI.changeActiveStatus(group);
    await axios.post(url);
    dispatch(loadGroups());
    const statusText = group.ativo
      ? intl.formatMessage({ defaultMessage: "ativado" })
      : intl.formatMessage({ defaultMessage: "desativado" });
    createSnackBar(intl.formatMessage({ defaultMessage: `Grupo {statusText} com sucesso.` }, { statusText }));
  });
};

export const onClickEditUser = user => async (dispatch, getState) => {
  const url = usersAPI.findById(user.id);

  const { featureToggles } = getState().usuario;
  withAjaxBlackoutErrorMessage(async () => {
    const { data } = await axios.get(url);
    data.infoVeridicas = !!data.infoVeridicas;

    // carrega dados específicos de CRM
    if (
      data.tipoPerfilId === EQUIPE_DEFS.vendedor.tipoPerfilId ||
      (data.tipoPerfilId === EQUIPE_DEFS.prevendedor.tipoPerfilId && featureToggles.empresaAgendamentoPorPv)
    ) {
      const { crmsList, crm } = await loadCrmUserData(data.id);
      data.crm = crm;
      data.crmsList = crmsList;
    }

    dispatch(openModalEdit(data));
  });
};

export const resetPassword = user => async (dispatch, getState) => {
  const intl = appIntl();
  const { id } = user ?? getState().equipe.modalEdit.target;
  const url = usersAPI.resetPassword(id);
  withAjaxBlackoutErrorMessage(async () => {
    await axios.post(url);
    createSnackBar(intl.formatMessage({ defaultMessage: "Senha resetada com sucesso." }));
  });
};

export const onCheckInativos = checked => async dispatch => {
  dispatch(createAction(types.ON_CHECK_INATIVOS, checked));
  dispatch(loadData());
};

export const loadGroups = () => async (dispatch, getState) => {
  dispatch(requestData());
  const { somenteAtivos } = getState().equipe;
  const url = groupsAPI.list(somenteAtivos);
  const { data } = await axios.get(url);
  dispatch(receiveDataSuccess({ data, canAdd: true }));
};

export const onClickEditGroup = group => async dispatch => {
  withAjaxBlackoutErrorMessage(async () => {
    const { data } = await axios.get(groupsAPI.findById(group));
    dispatch(openModalEdit(data));
  });
};

export const loadData = () => (dispatch, getState) => {
  const { isGroup } = getState().equipe;
  if (isGroup) {
    dispatch(loadGroups());
  } else {
    dispatch(loadUsers());
  }
};

const saveData = (url, data) => async dispatch => {
  withAjaxBlackoutErrorMessage(async () => {
    const intl = appIntl();
    await axios.post(url, data);
    dispatch(closeModalEdit());
    dispatch(loadData());
    const statusText = data.id
      ? intl.formatMessage({ defaultMessage: "Editado" })
      : intl.formatMessage({ defaultMessage: "Criado" });
    if (data.id || data.acessaSpotter) mutate("/api/pipeline/empresa/limiteusuarios");
    createSnackbar(intl.formatMessage({ defaultMessage: `{statusText} com sucesso.` }, { statusText }));
  });
};

const removeData = url => async (dispatch, getState) => {
  withAjaxBlackoutErrorMessage(async () => {
    const intl = appIntl();
    const { currentTab } = getState().equipe;
    await axios.post(url);
    dispatch(loadData());
    createSnackBar(intl.formatMessage({ defaultMessage: "Excluído com sucesso." }));

    if (currentTab !== EQUIPE_TAB_PATH.grupos) mutate("/api/pipeline/empresa/limiteusuarios");
  });
};

export const saveUser = user => async dispatch => {
  const userNoMask = {
    ...user,
    telefone1: user.telefone1?.replace(/\D/g, ""),
    telefone2: user.telefone2?.replace(/\D/g, ""),
  };

  try {
    if (userNoMask.crm) await saveCrmUserData(userNoMask);
    dispatch(saveData(usersAPI.save(), userNoMask));
  } catch ({ message }) {
    createSnackbar(message);
  }
};

export const saveGroup = group => dispatch => {
  dispatch(saveData(groupsAPI.save(), group));
};

export const removeUser = user => dispatch => {
  dispatch(removeData(usersAPI.remove(user)));
};

export const removeGroup = group => dispatch => {
  dispatch(removeData(groupsAPI.remove(group)));
};

async function loadCrmUserData(userId) {
  const [crmResponse, crmUserResponse] = await Promise.all([
    axios.get("/api/pipeline/CRM/BuscarLista"),
    axios.get(`/api/pipeline/CRM/Buscar?usuarioId=${userId}`),
  ]);

  return {
    crmsList: crmResponse.data || [],
    crm: {
      id: (crmUserResponse.data && crmUserResponse.data.crm) || 0,
      token: (crmUserResponse.data && crmUserResponse.data.token) || "",
    },
  };
}

async function saveCrmUserData(user) {
  const intl = appIntl();
  AjaxBlackout.Show();
  try {
    await axios.post("/Api/Pipeline/Usuario1/SalvarConfiguracoesCrmUsuario", {
      usuarioId: user.id,
      tipoCRM: user.crm.id,
      tokenCRM: user.crm.token,
    });
  } catch (e) {
    throw new Error(intl.formatMessage({ defaultMessage: "O token de integração não é válido." }));
  } finally {
    AjaxBlackout.Hide();
  }
}
